<template>
<base-page>
    <titlu-pagina Titlu="Dashboard" :AdaugaVisible='false'></titlu-pagina>

    <el-card style='margin:5px 0px 5px 0px'>
        <div slot="header" class="clearfix">
            <strong>Setari globale</strong>
        </div>
        <div v-loading="loadingVisible">
            <el-form @submit.prevent.native='save_info()'>
                <el-row :gutter='15'>
                    <el-col :md='6'>
                        <el-form-item label='SMTP Host' >
                            <el-input v-model='SMTP_Host' />
                        </el-form-item>
                    </el-col>
                    <el-col :md='6'>
                        <el-form-item label='SMTP Port' >
                            <el-input v-model='SMTP_Port' />
                        </el-form-item>
                    </el-col>
                    <el-col :md='6'>
                        <el-form-item label='SMTP Username' >
                            <el-input v-model='SMTP_User' />
                        </el-form-item>
                    </el-col>
                    <el-col :md='6'>
                        <el-form-item label='SMTP Password' >
                            <el-input v-model='SMTP_Password' />
                        </el-form-item>
                    </el-col>
                    <el-col :md='6'>
                        <el-form-item label='Email Administrator RoLegal' >
                            <el-input v-model='EmailAdminRolegal' />
                        </el-form-item>
                    </el-col>
                    <el-col :md='6'>
                        <el-form-item label='Email Administrator Servicii' >
                            <el-input v-model='EmailAdminServicii' />
                        </el-form-item>
                    </el-col>
                    <el-col :md='6'>
                        <el-form-item label='Subiect email' >
                            <el-input v-model='EmailSubject' />
                        </el-form-item>
                    </el-col>
                    <el-col :md='6'>
                        <el-form-item label='Testare' >
                            <el-button class='full-width' type='success' @click='test_email'>Trimite email test</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :md='24'>
                        <el-button type='primary' native-type='submit' > Salveaza </el-button>
                    </el-col>
                    <el-col :md='24'>
                        <p style='margin-top: 25px'>
                            <strong>Legenda</strong>
                        </p>
                        <p>
                            <el-tag>[NumeClient]</el-tag>
                            <el-tag>[NumeFirma]</el-tag>
                            <el-tag>[Adresa]</el-tag>
                            <el-tag>[DataExpirare]</el-tag>
                        </p>
                    </el-col>
                    <el-col :md='24'>
                        <el-form-item label='Continut' >
                            <editor
                                :api-key="apiKey"
                                v-model="EmailContent" 
                                :init="{
                                    height: 500,
                                    menubar: false,
                                    
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount image imagetools table'
                                    ],
                                    toolbar:
                                        [ 'undo redo | formatselect | fontsizeselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | removeformat | image ',
                                         '| table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
                                         ]  
                                    }"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :md='24'>
                        <el-button type='primary' native-type='submit' > Salveaza </el-button>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </el-card>
</base-page>

</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import TitluPagina from '../widgets/TitluPagina';
import Editor from '@tinymce/tinymce-vue'

export default {
    name: "dashboard",
    extends: BasePage,
    components: {
        'base-page'   : BasePage,
        'titlu-pagina': TitluPagina,
        'editor': Editor
    },
    data () {
        return {
            apiKey:'p6c4zd25tao155ex1uk6z3x2vms9tkqdcri5jmg7g6obrqow',
            SMTP_Host: '',
            SMTP_Port: '',
            SMTP_User:'',
            SMTP_Password: '',
            EmailAdminRolegal:'',
            EmailAdminServicii:'',
            EmailSubject:'',
            EmailContent: ''
        }
    },
    methods: {
        async get_info() {
            this.select_menu_item('dashboard');
            this.refresh_info();
        },

        async refresh_info() {
            var response = await this.post("dashboard/get_info");
            this.SMTP_Host          = response.SMTP_Host;
            this.SMTP_Port          = response.SMTP_Port;
            this.SMTP_User          = response.SMTP_User;
            this.SMTP_Password      = response.SMTP_Password;
            this.EmailContent       = response.EmailContent;
            this.EmailAdminRolegal  = response.EmailAdminRolegal;
            this.EmailAdminServicii = response.EmailAdminServicii;
            this.EmailSubject       = response.EmailSubject;
        },

        async test_email(){
            await this.post("dashboard/test_email");
        },

        async save_info() {
            await this.post("dashboard/save_info", {
                SMTP_Host:          this.SMTP_Host,
                SMTP_Port:          this.SMTP_Port,
                SMTP_User:          this.SMTP_User,
                SMTP_Password:      this.SMTP_Password,
                EmailContent:       this.EmailContent,
                EmailAdminRolegal:  this.EmailAdminRolegal,
                EmailAdminServicii: this.EmailAdminServicii,
                EmailSubject:       this.EmailSubject
            });
        }

    },
    mounted(){
        this.get_info();
    }
};
</script>

<style lang="less" scoped>
.el-form-item__label{
    display: block !important;
    float: none !important;
    text-align: left !important;
}

.el-tag{
    margin-right: 15px;
}
.top50{
    margin-top: 20px;
}

</style>
